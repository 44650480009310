const formatDate = (dateString: string | Date, opts: Intl.DateTimeFormatOptions = {}) => {
	const date = new Date(dateString)
	const options: Intl.DateTimeFormatOptions = {
		day: '2-digit',
		hour: '2-digit',
		year: '2-digit',
		month: '2-digit',
		minute: '2-digit',
		...opts,
	}
	return date.toLocaleDateString('ru-RU', options)
}

export { formatDate }
